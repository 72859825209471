



































































































































import { Component, Watch, Vue } from 'vue-property-decorator';
import { Debounce } from 'vue-debounce-decorator';
import axios, { AxiosPromise } from 'axios';
import store from '../store';
import User from '../models/user';
import Page from '../models/page';

@Component
export default class Registration extends Vue {
    private user: User = {
        email: null,
        firstname: null,
        lastname: null,
        password: null,
        pageId: this.page.id,
        assignmentFeature: null,
    };
    private passwordRepeat: string = '';
    private passwordMismatch: boolean = false;
    private loading: boolean = false;
    private success: boolean = false;
    private error: boolean = false;
    private message: string = '';
    private get page(): Page {
        return store.state.page;
    }

    private register(): AxiosPromise {
        return axios.post('https://bundk-api.bdrops.space/api/register', this.user);
    }

    private registerUser(): void {
        this.success = false;
        this.error = false;
        this.message = '';
        if (!this.passwordMismatch) {
            this.loading = true;
            this.register().then(() => {
                this.success = true;
                this.message = 'Sie haben sich erfolgreich registriert. Sehen Sie jetzt bitte in Ihr E-Mail Postfach, um Ihre Registrierung abzuschließen! Überprüfen Sie bitte auch Ihren Spam-Ordner.';
            }, (error) => {
                this.error = true;
                this.message = 'Leider ist bei Ihrer Registrierung ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.';
                if (error.response) {
                    const data = error.response.data;
                    if (data.reason === 'User exists') {
                        this.message = 'Sie haben sich bereits registriert!';
                    }
                    if (data.reason === 'Invalid email') {
                        this.message = 'Die eingegebene E-Mail Adresse ist ungültig!';
                    }
                }
            }).then(() => {
                this.loading = false;
            });
        }
    }

    @Watch('passwordRepeat')
    @Debounce(500)
    private onPasswordRepeatChange(newVal: string) {
        if (this.user.password && newVal !== this.user.password) {
            this.passwordMismatch = true;
        }
        if (this.user.password && newVal === this.user.password) {
            this.passwordMismatch = false;
        }
        if (this.user.password === '' || newVal === '') {
            this.passwordMismatch = false;
        }
    }
}
